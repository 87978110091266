import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import logoMe from '../images/me.jpg';


export default function NavBar(props) {
    console.log("props drilled: ", props);
    return (
        <section className="navbar">
            <h1 className="nameTitle"> Joshua Shockley </h1>
            <Image className="aboutMeImg" src={logoMe}
                alt="picture of my face"
                wraped="true" />

            <div className="nav-links">
                <div className="topLink"><a href="#top" title="to the top of home page">To Top</a></div>


                <NavLink exact to='/'
                    className="navlink"
                    title="Link to Landing Page, aka: Home"


                > Home </NavLink>
                {/* resume is currently looking at the inprogress page.. needs to be changed once complete the resume page layout */}
                <NavLink exact to='/inProgress'
                    title="View My Resume"
                    className="navlink"

                > Resume </NavLink >
                {/* the below is to link to a personal projects page eventually.. make a in progress page to see and bring back to home for now */}
                <NavLink exact to='/Personal'
                    className="navlink "
                    title="not a working link yet"
                > Personal </NavLink>
            </div>
        </section >
    );
};
