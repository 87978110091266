import React from 'react';
import ghFCimage from '../images/MyGithubFC.png';
import idFCimage from '../images/MyIndeed.com.png';
import linkedInFCimage from '../images/MyLInkedInFC.png';

export default function Footer() {
    // to use for jsx mapped into return below for QR codes
    const qrObjects = [
        {
            class: "qrcode",
            title: "My Github",
            qrCodeImage: ghFCimage,
        },
        {
            class: "qrcode",
            title: "My LinkedIn",
            qrCodeImage: linkedInFCimage,
        },
        {
            class: "qrcode",
            title: "My Indeed",
            qrCodeImage: idFCimage,
        }
    ]
    // to use for jsx mapped into return below for credits.
    const theCredits = [
        {
            by: "Icons made by",
            link: "https://www.flaticon.com/authors/smashicons",
            linkName: "Smashicons",
            from: <a href="https://www.flaticon.com/" target="blank" rel="noopener noreferrer" title="Flaticon">www.flaticon.com</a>,
        },
        {
            by: "Header background image by ",
            link: "https://unsplash.com/@cdr6934?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
            linkName: "Chris Reid",
            from: "Unsplash",
        },
        {
            by: "About Me image by ",
            link: "https://unsplash.com/@szolkin",
            linkName: "Sergey Zolkin",
            from: "Unsplash",
        },
        {
            by: "QR Codes by",
            link: "http://www.flowcode.com",
            linkName: "Flowcode.com",
            from: "Flowcode"
        }

    ]

    return (
        <div className="footer-container" >
            <div className="footerUsableSpace">
                <br />
                <h4>Scan the QR codes below to get know more</h4>
                <br />
                <div className="qrGrid">
                    {
                        qrObjects.map((thing) =>
                            //this needs a empty div for proper rendering.. the line break "br" causes the need for it.
                            <>
                                <br />
                                <div className="footerSquare">
                                    <h5>{thing.title}</h5>
                                    <img className={thing.class} src={thing.qrCodeImage} wraped="true" title={thing.title} />
                                </div>
                                <br />
                            </>
                        )
                    }
                </div>
                <section className="creditSection">
                    <p className="creditTitle"> Thanks to the following for the open source use of the images</p>
                    <br />
                    {
                        theCredits.map((info) =>
                            <h6 className="creditTitle" >{info.by} <a href={info.link} target="blank">{info.linkName}</a> from {info.from}</h6>)
                    }
                    <br />
                    <br />
                    <p className="cute-note">This Portfolio was developed with React using CRA to initiate it and is deployed using Vercel</p>
                    <p className="cute-note">This SPA (single page application) was setup using NPM create-react-app command</p>
                    <p className="cute-note">This project is also using GIT for branch management</p>
                    <br />
                    <p className="cute-note">Also a <strong>BIG</strong> thanks to my wife who puts up with all the clicking sounds while I'm working on additions or adaptations. She is <strong>AMAZING</strong> and was patient with my late nights during my entire learning process</p>
                    <br />
                </section>
            </div>

        </div>
    );
};