import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
// import Projects from './components/Projects'
import NavBar from './components/NavBar';
import InProgress from './components/InProgress';
import Footer from './components/Footer';
import Personal from './components/Personal.js';
import Resume from './components/Resume';
import Test from './components/Test.js';

import CData from '../src/components/CData.js';

// eventually will connect a backend to this and
// all the stuff will need to be brought
// through props or context api with a wrapper...
function App() {
  const [navState, setNavState] = useState("home")
  return (
    <div className="App">
      <header className="App-header">
        <NavBar theState={navState} setTheState={setNavState} />
      </header>
      <section className="mainPageBody">
        <Switch>
          <Route exact path="/" component={Home} />

          {/* switch out the below once Resume is completed */}
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/personal" component={Personal} />
          <Route exact path="/inProgress" component={InProgress} />
          <Route exact path="/test" component={Test} />
        </Switch>
      </section>
      <Footer />
    </div>
  );
}

export default App;
