import React from 'react';

export default function InProgress() {

    return (
        <section className="inProgress-container">
            <div className="progress-box">
                <h1>This  portion of the site is currently under construction.... </h1>
                <p>Currently Crafting My Resume to be viewed here with all previous aspects of my work History</p>
                <br />
                <br />
                <br />
                <br />
                <h5>So if you would like use the below link to look into my very first <a href='https://joshua-shockley.github.io/portfolio-website/' target='blank' rel="noopener noreferrer">Portfolio</a> using a template) ...</h5>
            </div>
            {/* <div className="topLink"><a href="#top" title="to the top of home page">to top</a></div> */}


        </section>
    )
}