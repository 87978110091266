import React, { useState } from 'react';
import Projects from './Projects';
import ghub from '../images/logo.png';
import lnkIn from '../images/linkedin.png';
import axiosimg from '../images/axios-logo.png';
import reactimg from '../images/react.png';
import html5img from '../images/html-5.png';
import lessimg from '../images/lessLogo.png';
import hookimg from '../images/hooks.png';
import sassimg from '../images/sassLogo.png';
import sqlimg from '../images/sqlite3.png';
import cssimg from '../images/css.png';
import nodeimg from '../images/nodejs.png';
import restimg from '../images/rest.jpeg';
import pythonimg from '../images/pythonLogo.png';

import CData from '../components/CData.js';


export default function Home(props) {
    const [theData, setTheData] = useState(CData);
    console.log(theData);

    let rNum;
    function randIndex(theData) {
        rNum = Math.floor(Math.random(0, 2) * theData.length);
        console.log(rNum);
        console.log("theData[rNum]  ", theData[rNum]);
        return rNum;
    };
    let stateIndex = randIndex(theData);


    const [picked, setPicked] = useState(theData[stateIndex]);


    // console.log("picked", picked)


    return (
        <section className="homePage">
            <div className="HomeIntro">
                <div className="skills">
                    <h1 className="maketitle"> Let's Make Stuff Together!!  </h1>
                    <h3 className="maketitle2">Experienced With The Below But Excited To Obtain New Skills</h3>
                    {/* make them smaller when on mobile devices */}
                    <div className="iconPics">
                        <img src={html5img} alt=" Experience with html5" title="HTML5" />
                        <img src={cssimg} alt="Experience with CSS" title="CSS3" />
                        <img src={lessimg} alt="Experience with LESS" title="LESS" />
                        <img src={sassimg} alt="Experience with SASS" title="SASS" />
                        <img src={reactimg} alt="Experience with Single Web Page React Apps" title="SPA, Single Page App" />
                        <img src={hookimg} alt="Experience with React Hooks" title="REACT HOOKS" />
                        <img src={axiosimg} alt="Experience with AXIOS" title="AXIOS" />
                        <img src={restimg} alt="Experience with REST API" title="REST API" />
                        <img src={pythonimg} alt="Exerience with Python" title="python3" />

                        <img src={nodeimg} alt="Experience with Node.js" title="NODE.JS" />

                        <img src={sqlimg} alt="Experience with sqlite3" title="SQLITE3" />
                    </div>
                </div>
                <div className="iconLinks">
                    <div>
                        <a href="https://github.com/joshua-shockley" target="_blank" rel="noopener noreferrer" title="to my Github"><img src={ghub} alt="link to my github"></img></a>
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/in/joshuawdshockley" target="_blank" rel="noopener noreferrer" title="to my Linkedin"><img src={lnkIn} alt="link to my Linkedin" /></a>
                    </div>
                </div>
                <section className="about-words" title="ABOUT ME">
                    <div className="one-about" >
                        <h4>{picked.title}</h4>
                        <p>{picked.captionText}</p>
                    </div>
                </section>
            </div>
            <div className="ProjectSection" title="MY PROJECTS">
                <Projects />
            </div>
            {/* <div className="topLink"><a href="#top" title="to the top of home page">To Top</a></div> */}
        </section >
    );
};