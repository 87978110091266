import React from 'react';


export default function Edu() {
    //the idea is to modularize each part so that adjustments to this information is easy to get too
    const education = [
        {
            name: "Lambda",
            completed: "May 2019 - present",
            degree: "Endorsement Pending"

        },
        // {
        //     name: "MSU",
        //     completed: "find data for the date",
        //     degree: "cert or degree or endorsement status"

        // },
        {
            name: "Ozark Technical Community College",
            completed: "2008",
            degree: "Associates Degree"

        },

    ]

    return (
        <div>
            <h3>Education : </h3>
            {/* {education[0].name !== "name" ?
                <div> data is updated whenever you want to setup the actual container for the information </div> : <div> not data yet </div>} */}

            {education.map((edu) =>
                <div id="education">
                    <h5>{edu.degree}</h5>
                    <h6>{edu.completed}</h6>
                    <h5>{edu.name}</h5>
                </div>
            )}

        </div>
    );
};