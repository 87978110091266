import React from 'react';
import Skills from './Skills.js';
import PreEx from './PreEx.js';
import Edu from './Edu.js';
import Cert from './Cert.js';
import Awards from './Awards.js';



export default function Resume() {

    //sections imported to make updates easier and modular... 

    return (
        <section>
            {/* <h1>this is the resume page</h1> */}
            <div className='resume-container'>
                {/* <h2> This section currently under construction</h2> */}
                <hr />
                <section className="profileLinks">

                    <h4>
                        <a href="https://my.indeed.com/p/joshuas-8db38z6" target="blank" rel="noopener noreferrer" title="takes you to my InDeed profile resume">INDEED.COM resume</a>

                    </h4>
                    <br />
                    <h4><a href="https://www.linkedin.com/in/joshuawdshockley" target="blank" rel="nooperner noreferrer" title="takes you to my LinkedIn">LINKEDIN.COM PROFILE</a></h4>
                    <br />
                    <h4><a href="https://github.com/joshua-shockley" target="blank" rel="nooperner noreferrer" title="takes you to my Github">GITHUB.COM PROFILE</a></h4>

                </section>
                <hr />
                <br />
                <div>
                    <Skills />
                </div>
                {/* this will be the bottom can split this up... 
                    it handles the other stuff... lol! */}
                <div id="additional-info">
                    <PreEx />
                    <Edu />
                    <Cert />
                    <Awards />
                </div>
            </div>
        </section>
    );
};

