import React from 'react';


export default function PreEx() {
    //the idea is to modularize each part so that adjustments to this information is easy to get too

    const pExp = [
        {
            company: "ADS",
            started: "09-2020",
            left: "current",
            position: "Intern Programmer",
            location: "Ozark, Mo.",
            responsibilitites: [
                "Developing databases utilizing MS Access.",
                "Listening to customer needs to help streamline frontend useability",
                "Collaborate as a team to provide an excellent client product"
            ]
        },
        {
            company: "ATT",
            started: "11-2014",
            left: "04-2019",
            position: "Premise Technician",
            location: "Springfield, Mo.",
            responsibilitites: [
                "Connected in home services from outside network lines",
                "Serviced in home fiber products for well rounded customer service",
                "Studied Theory Of Fiber Networks (attaining a national certification from KU) to better understand customer needs",
                "Learned self improvement techniques and safety methods regulalry",
                "Maintained personal supplies logistics for company truck within required timelines",
                "Awarded for great customer experience going above and beyond for difficult situations"
            ],
        },
        {
            company: "ARC of The Ozarks",
            started: "08-2010",
            left: "11-2014",
            position: "Behavioral Specialist Technician - Level II",
            location: "Springfield, Mo.",
            responsibilitites: [
                "Supported clients mentoring needs for better life choices",
                "Mentored consistent practice of necessary life skills with persons needing additional assistance",
                "Provided logistical planning for daily tasks and Medical needs for clients",
                "Currated the learning development with activites to promote positive and productive daily living",
                "Collaborated with clients for an exciting and healthy culinary experience"
            ],
        },


    ]


    return (
        <div>
            <h3>Previous Experience : </h3>
            {/* {pExp[0].company !== "companyName" ?
                <div> data is updated whenever you want to setup the actual container for the information </div>
                :
                <div> not data yet </div>} */}
            {pExp.map((exp) =>
                <div>
                    <div>{exp.company}</div>
                    <div>{exp.started}</div>
                    <div>{exp.left}</div>
                    <div>{exp.position}</div>
                    <div>{exp.location}</div>
                    <div>
                        <h4>Position Descriptions:</h4>
                        {/* now we map through the list in the object */}
                        <ul>{exp.responsibilitites.map((resp) =>
                            <li>{resp}</li>
                        )}</ul>
                        <br />
                    </div>
                </div>
            )}

        </div>
    );
};