import React from 'react';
import llGiph from '../gifs/ezgif.com-gif-maker.gif';
import clickerGiph from '../gifs/ezgif.com-crop.gif'
export default function Test() {


    return (
        <section>
            hello motha lovah! this is what i use to test stuff out before it moves into main site...
        </section>
    )
};
