// this is where I will put more personal things i want to show case or make it imported as a list that only shows on hover like a menu

import React from 'react'


export default function Personal() {


    return (
        <section className="personal-container">
            <h1>Thank You For Your Interest</h1>
            <div>
                <p>This portion is still under construction and will take a little time to get going as I don't have all the needed data to put together the page showing my own personal in home projects.. </p>
            </div>
            {/* <div className="topLink"><a href="#top" title="to the top of home page">to top</a></div> */}

        </section>
    );
};