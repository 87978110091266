
// this will be imported later on to have random pick at window load as to which will be shown.
export default [{
    numberText: '1',
    title: 'The Crafty One',
    captionText: 'Enjoying crafting things as a child forged a need for me as an adult to use my imagination. This NEED along with some good research always leads me to new projects whether in home or online. I love to work with my hands and my imagination to help solve problems whether someone else’s or my own.',

}, {
    numberText: '2',
    title: 'The Team Player',
    captionText: "I've been an advocate for improving team dynamics for more than 10 years. I've always enjoyed working in a team, even though I also do well on my own, I really enjoy maximizing my time manangement while also increasing productivity through pair programming. I've found pair programming to be a great tool in which to maximize productivity. ",


}, {
    numberText: '3',
    title: 'Jack-Of-All-Trades',
    captionText: "I relish learning new things and have always kept myself busy with new projects that require some solid amount of research. From remodeling my own home to working with a small homemade laptop fan; I have the habit of diving in and looking more than just the hot tip on how to do something. I really love to understand why things work the way they do. So far my favorite is the electric shovel guitar, Learning how to setup the electronics was exciting and fun. I'll relish using that 'Jack-Of-All-Trade' skillset to anything I dive into while working to give the best product possible.",


}];